import validate from "/data/runners/six/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/nuxt@3.12.1_@parcel+watcher@2.5.0_@types+node@22.13.10_bufferutil@4.0.9_db0@0.2.1_eslin_cae75c569e017aa238f57f071e372c70/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/data/runners/six/_work/apps-monorepo/apps-monorepo/apps/pay/middleware/auth.global.js";
import manifest_45route_45rule from "/data/runners/six/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/nuxt@3.12.1_@parcel+watcher@2.5.0_@types+node@22.13.10_bufferutil@4.0.9_db0@0.2.1_eslin_cae75c569e017aa238f57f071e372c70/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "company-guard": () => import("/data/runners/six/_work/apps-monorepo/apps-monorepo/apps/pay/middleware/companyGuard.js")
}