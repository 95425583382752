import revive_payload_client_8jyPO2qiR1 from "/data/runners/six/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/nuxt@3.12.1_@parcel+watcher@2.5.0_@types+node@22.13.10_bufferutil@4.0.9_db0@0.2.1_eslin_cae75c569e017aa238f57f071e372c70/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_OsUE1GlVZ3 from "/data/runners/six/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/nuxt@3.12.1_@parcel+watcher@2.5.0_@types+node@22.13.10_bufferutil@4.0.9_db0@0.2.1_eslin_cae75c569e017aa238f57f071e372c70/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_cPYKFtb7lU from "/data/runners/six/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/nuxt@3.12.1_@parcel+watcher@2.5.0_@types+node@22.13.10_bufferutil@4.0.9_db0@0.2.1_eslin_cae75c569e017aa238f57f071e372c70/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import check_outdated_build_client_z8XpejQc5V from "/data/runners/six/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/nuxt@3.12.1_@parcel+watcher@2.5.0_@types+node@22.13.10_bufferutil@4.0.9_db0@0.2.1_eslin_cae75c569e017aa238f57f071e372c70/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_pDn6tiUuqj from "/data/runners/six/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/@pinia+nuxt@0.4.11_magicast@0.3.5_rollup@4.23.0_typescript@5.7.2_vue@3.5.13_typescript@5.7.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/data/runners/six/_work/apps-monorepo/apps-monorepo/apps/pay/.nuxt/components.plugin.mjs";
import prefetch_client_CIHUsPEZjX from "/data/runners/six/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/nuxt@3.12.1_@parcel+watcher@2.5.0_@types+node@22.13.10_bufferutil@4.0.9_db0@0.2.1_eslin_cae75c569e017aa238f57f071e372c70/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_client_r0rlmtajHN from "/data/runners/six/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/@vite-pwa+nuxt@0.5.0_magicast@0.3.5_rollup@4.23.0_vite@5.4.11_@types+node@22.13.10_sass_6b0ae41f4d2d04807fbdffb733f4417c/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import chunk_reload_client_eprkv8m2HZ from "/data/runners/six/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/nuxt@3.12.1_@parcel+watcher@2.5.0_@types+node@22.13.10_bufferutil@4.0.9_db0@0.2.1_eslin_cae75c569e017aa238f57f071e372c70/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_Z1YrzSGfgD from "/data/runners/six/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.0.0_@pinia+nuxt@0.4.11_magicast@0.3.5_rollup@4.23.0_f21a741b9a4af588c2390f32b75073eb/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import clerk_client_sAvp4qAF8G from "/data/runners/six/_work/apps-monorepo/apps-monorepo/apps/pay/plugins/clerk.client.js";
import dateFns_client_zmk3aCrZdB from "/data/runners/six/_work/apps-monorepo/apps-monorepo/apps/pay/plugins/dateFns.client.js";
import filters_client_leW4wqvSMQ from "/data/runners/six/_work/apps-monorepo/apps-monorepo/apps/pay/plugins/filters.client.js";
import fontawesome_client_3AEyghtEUi from "/data/runners/six/_work/apps-monorepo/apps-monorepo/apps/pay/plugins/fontawesome.client.js";
import intercom_client_sjSaVpgP3i from "/data/runners/six/_work/apps-monorepo/apps-monorepo/apps/pay/plugins/intercom.client.js";
import loader_pK85tHLquW from "/data/runners/six/_work/apps-monorepo/apps-monorepo/apps/pay/plugins/loader.js";
import lottie_client_funiu1oBjt from "/data/runners/six/_work/apps-monorepo/apps-monorepo/apps/pay/plugins/lottie.client.js";
import mask_client_hSGYCqJ9ZQ from "/data/runners/six/_work/apps-monorepo/apps-monorepo/apps/pay/plugins/mask.client.js";
import ofetch_client_kdTHiAedZ8 from "/data/runners/six/_work/apps-monorepo/apps-monorepo/apps/pay/plugins/ofetch.client.js";
import pinia_client_MzxIWzYLDp from "/data/runners/six/_work/apps-monorepo/apps-monorepo/apps/pay/plugins/pinia.client.js";
import places_Op3OtRUgO6 from "/data/runners/six/_work/apps-monorepo/apps-monorepo/apps/pay/plugins/places.js";
import recaptcha_W4US4xmBV6 from "/data/runners/six/_work/apps-monorepo/apps-monorepo/apps/pay/plugins/recaptcha.js";
import rsk_7zLHSUX3HB from "/data/runners/six/_work/apps-monorepo/apps-monorepo/apps/pay/plugins/rsk.js";
import select_LicOUSSwxZ from "/data/runners/six/_work/apps-monorepo/apps-monorepo/apps/pay/plugins/select.js";
import sentry_client_GoGQuZo4Et from "/data/runners/six/_work/apps-monorepo/apps-monorepo/apps/pay/plugins/sentry.client.js";
import toast_client_437j7GlIrd from "/data/runners/six/_work/apps-monorepo/apps-monorepo/apps/pay/plugins/toast.client.js";
import tooltip_07WqZjDZUO from "/data/runners/six/_work/apps-monorepo/apps-monorepo/apps/pay/plugins/tooltip.js";
import vCalendar_client_K64EuUrEFD from "/data/runners/six/_work/apps-monorepo/apps-monorepo/apps/pay/plugins/vCalendar.client.js";
import web3modal_client_2yvFzQ6iu1 from "/data/runners/six/_work/apps-monorepo/apps-monorepo/apps/pay/plugins/web3modal.client.js";
export default [
  revive_payload_client_8jyPO2qiR1,
  unhead_OsUE1GlVZ3,
  router_cPYKFtb7lU,
  check_outdated_build_client_z8XpejQc5V,
  plugin_vue3_pDn6tiUuqj,
  components_plugin_KR1HBZs4kY,
  prefetch_client_CIHUsPEZjX,
  pwa_client_r0rlmtajHN,
  chunk_reload_client_eprkv8m2HZ,
  plugin_Z1YrzSGfgD,
  clerk_client_sAvp4qAF8G,
  dateFns_client_zmk3aCrZdB,
  filters_client_leW4wqvSMQ,
  fontawesome_client_3AEyghtEUi,
  intercom_client_sjSaVpgP3i,
  loader_pK85tHLquW,
  lottie_client_funiu1oBjt,
  mask_client_hSGYCqJ9ZQ,
  ofetch_client_kdTHiAedZ8,
  pinia_client_MzxIWzYLDp,
  places_Op3OtRUgO6,
  recaptcha_W4US4xmBV6,
  rsk_7zLHSUX3HB,
  select_LicOUSSwxZ,
  sentry_client_GoGQuZo4Et,
  toast_client_437j7GlIrd,
  tooltip_07WqZjDZUO,
  vCalendar_client_K64EuUrEFD,
  web3modal_client_2yvFzQ6iu1
]